<template>
  <div>
    <template v-if="!loading.overall">
      <v-container fluid>
        <div style="height: 5vh" class="mb-3">
          <div class="row">
            <div class="col text-right"></div>
          </div>
        </div>
        <div style="height: 15vh">
          <div
            style="
              display: flex;
              height: 100%;
              align-items: center;
              justify-content: center;
              margin-top: 2vh;
            "
          >
            <img src="@/assets/logos/dime_logo_gold.png" style="height: 7em" />
          </div>
        </div>
        <div style="height: 66.5vh">
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-content: flex-end;
            "
          >
            <!-- <img
              src="@/assets/logos/dime_thinkhigher.png"
              style="height: 2.5em"
            /> -->
          </div>
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-content: flex-end;
            "
          ></div>
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              height: 82%;
            "
          >
            <form style="width: 100%">
              <div class="mb-3">
                <div
                  class="w-100 py-3"
                  :style="`
                  background-color: #000;
                  border: 2px solid ${getAccentColor};
                  border-radius: 30px;`"
                  @click="traverse('deviceList')"
                >
                  <span
                    class="
                      d-block
                      text-center text-uppercase text-white
                      fw-bold
                    "
                    >paired devices</span
                  >
                </div>
              </div>
              <div class="mb-3">
                <div
                  class="w-100 py-3"
                  :style="`
                  background-color: #000;
                  border: 2px solid ${getAccentColor};
                  border-radius: 30px;`"
                  @click="traverse('faq')"
                >
                  <span
                    class="
                      d-block
                      text-center text-uppercase text-white
                      fw-bold
                    "
                    >FAQs</span
                  >
                </div>
              </div>
              <div class="mb-3">
                <div
                  class="w-100 py-3"
                  :style="`
                  background-color: #000;
                  border: 2px solid ${getAccentColor};
                  border-radius: 30px;`"
                  @click="traverse('support')"
                >
                  <span
                    class="
                      d-block
                      text-center text-uppercase text-white
                      fw-bold
                    "
                    >SUPPORT</span
                  >
                </div>
              </div>
              <!--<div class="mb-3">-->
              <!--<div-->
              <!--class="w-100 py-3"-->
              <!--:style="`-->
              <!--background-color: #000;-->
              <!--border: 2px solid ${getAccentColor};-->
              <!--border-radius: 30px;`"-->
              <!--&gt;-->
              <!--<span-->
              <!--class="-->
              <!--d-block-->
              <!--text-center text-uppercase text-white-->
              <!--fw-bold-->
              <!--"-->
              <!--&gt;PLACEHOLDER</span-->
              <!--&gt;-->
              <!--</div>-->
              <!--</div>-->

              <div class="pt-3 mb-3">
                <div
                  class="w-100 py-1"
                  :style="`
                  border: none;
                  background-color: ${getActionColor};
                  border-radius: 30px;`"
                  @click="traverse('logout')"
                >
                  <span
                    class="
                      d-block
                      text-white text-uppercase
                      fw-bold
                      text-center
                    "
                    style="font-size: 1.7em"
                    >logout</span
                  >
                </div>
              </div>
            </form>
          </div>
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              padding-bottom: 4vh;
            "
          >
            <img
              style="height: 3.5em"
              src="@/assets/logos/one_logo_black.png"
            />
          </div>
        </div>
        <Footer />
      </v-container>
    </template>
    <template v-else> Loading... </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import user from "@/api/user";

// Components
import Footer from "@/components/Global/Footer.vue";
import bluetoothRepository from "../bluetooth/BluetoothRepository";
import router from "../router";

export default {
  name: "login",
  components: {
    Footer,
  },
  data() {
    return {
      loading: {
        overall: true,
      },
      response: {},
      payload: {},
    };
  },
  created() {
    this.setup();
  },
  methods: {
    ...mapActions([
      "_setLoginState",
      "_setLoginInfo",
      "_setRegisterEmail",
      "_setPresetInfo",
    ]),
    /* API Calls */
    // GET
    // POST
    /* Main */
    async setup() {
      await this.setLoadingState(true);
      // Methods
      await this.setLoadingState(false);
    },
    traverse(action) {
      switch (action) {
        case "deviceList":
          this.$router.push({
            name: "deviceList",
          });
          break;
        case "faq":
          this.$router.push({
            name: "faq",
          });
          break;
        case "support":
          this.$router.push({
            name: "support",
          });
          break;
        case "logout":
          bluetoothRepository.disconnect();

          this.showLoading("Waiting");
          user.logout().finally(() => {
            this.hideLoading();
            window.localStorage.setItem("front-token", "");
            this._setRegisterEmail("");
            this._setLoginState(false);
            this._setLoginInfo(null);
            this._setPresetInfo(null);
            this.$router.replace({
              name: "login",
            });
          });

          break;
      }
    },
    /* Misc */
    async setLoadingState(loadingState) {
      this.loading.overall = loadingState;
    },
  },
  computed: {
    ...mapGetters(["getMainColor", "getAccentColor", "getActionColor"]),
  },
};
</script>

<style></style>
